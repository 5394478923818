export function isUndefined(object) {
    return object === undefined;
}

export function isNull(object) {
    return object === null;
}

export function which(list, item, key) {
    const get = isUndefined(key) ? (l, i) => l[i] : (l, i) => key(l[i]);
    for (let i=0; i<list.length; i++) {
        if (get(list, i) === item) { return i; }
    }
    return -1;
}

export function getSearchParam(name, defaultValue) {
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(name);
    return isNull(value) ? defaultValue : value;
}

export function isMobile(){
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}


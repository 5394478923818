<template>
  <el-row :class="speciesLinkClasses">
    <el-col :span="6">
      <img :src="logo" :alt="name" class="species-logo">
    </el-col>
    <el-col :span="18" class="species-description">
      <div :class="speciesNameClasses">
        <el-link :href="url">{{ name }}</el-link>
      </div>
      <div class="species-sciname"><el-text type="info"><i>{{ sciname }}</i></el-text></div>
    </el-col>
  </el-row>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  active: Boolean,
  url: String,
  logo: String,
  name: String,
  sciname: String
})

const speciesLinkClasses = props.active ? ['species-link', 'active'] : ['species-link'];
const speciesNameClasses = props.active ? ['species-name', 'active'] : ['species-name'];
</script>

<style scoped>
.species-link {
  height: 3.8rem;
  margin-bottom: .3125rem;
  border-radius: .3125rem;
  border: 1px solid var(--el-border-color-light);
}
.species-link.active {
  border: 1px solid var(--main-color);
}
.species-logo {
  height: 3.8rem;
}
.species-description {
  padding: .3125rem;
}
.species-name {
  margin-bottom: .125rem;
}
.species-name.active > a {
  color: var(--main-color);
}
</style>
<template>
  <div class="footer">
    <p class="copyright">版权所有&copy;国家医学媒介生物监测重点实验室（广东）</p>
    <p class="beian">
      <el-link target="_blank" href="https://beian.miit.gov.cn/" class="easy-footer-link">
        浙ICP备2023012803号
      </el-link>
    </p>
  </div>
</template>

<script setup>
</script>

<style scoped>
.copyright {
  color: var(--el-color-info);
  text-align: center;
  margin: 1rem 0 0 0;
}
.beian {
  text-align: center;
  margin: 0;
}
.easy-footer-link {
  color: var(--el-color-info);
  text-decoration: none;
}
</style>
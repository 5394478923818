<template>
  <el-container class="container">
    <el-header class="header"><easy-header /></el-header>
    <el-main class="main">
      <el-row class="content">
        <el-col :span="20" class="wrapper">
          <div class="waiting" v-show="! loaded">模型数据加载中，请稍后...</div>
          <div class="viewer" v-show="loaded"></div>
        </el-col>
        <el-col :span="4">
          <species-link
              v-for="species in speciesList"
              :key="species.id"
              :active="species.id === id"
              :url="'/index.html?id=' + species.id"
              :name="species.name"
              :sciname="species.sciname"
              :logo="species.logo"
          />
        </el-col>
      </el-row>
    </el-main>
    <el-footer><easy-footer /></el-footer>
  </el-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import SpeciesLink from '@/components/SpeciesLink.vue';
import { speciesList } from '@/data.js';
import { which, getSearchParam } from '@/utils.js';
import EasyHeader from '@/components/EasyHeader.vue';
import EasyFooter from '@/components/EasyFooter.vue';

const id = getSearchParam('id', '1');
const i = which(speciesList, id, species => species.id);
const currentSpecies = speciesList[i];
const loaded = ref(false);

onMounted(() => {
  const scene = new THREE.Scene();
  const main = document.getElementsByClassName('main')[0];
  const wrapper = document.getElementsByClassName('wrapper')[0];
  const viewer = document.getElementsByClassName('viewer')[0];
  const style = getComputedStyle(main);
  const elMainPaddingString = style.getPropertyValue('--el-main-padding');
  const elMainPadding = parseInt(elMainPaddingString.slice(0, elMainPaddingString.length - 2));
  const width = wrapper.clientWidth - elMainPadding;
  const height = main.clientHeight - 2 * elMainPadding;
  const aspect = width / height;
  const camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 10000);
  camera.position.set(0.5, 0.5, 0.5);
  camera.lookAt(scene.position);
  scene.background = new THREE.Color(0x888888);
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(width, height);
  renderer.shadowMap.enabled = true;
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  const light = new THREE.DirectionalLight(0xffffff, 2);
  light.position.set(100, 100, 100);
  const light2 = new THREE.DirectionalLight(0xffffff, 2);
  light.position.set(-100, 100, -100);
  const ambient = new THREE.AmbientLight(0xffffff, 0.5);
  scene.add(camera, light, light2, ambient);
  const planeGeometry = new THREE.PlaneGeometry(50, 50);
  const planeMaterial = new THREE.MeshBasicMaterial({ color: 0x888888 });
  const plane = new THREE.Mesh(planeGeometry, planeMaterial);
  plane.receiveShadow = true;
  plane.rotation.x = -Math.PI / 2;
  plane.position.y = -1;
  scene.add(plane);
  const animate = () => {
    requestAnimationFrame(animate);
    controls && controls.update();
    renderer.render(scene, camera);
  };
  animate();
  const loader = new GLTFLoader();
  loader.load(currentSpecies.model, (gltf) => {
    gltf.scene.position.set(0, 0, 0);
    scene.add(gltf.scene);
    loaded.value = true;
    viewer.appendChild(renderer.domElement);
  });
})
</script>

<style scoped>
.container {
  height: 100vh;
}
.header {
  background-color: var(--main-color);
  text-align: center;
  line-height: var(--el-header-height);
}
.main {
  height: calc(100vh - var(--el-header-height) - var(--el-footer-height));
}
.waiting {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
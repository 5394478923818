export const speciesList = [
    {
        id: '1',
        name: '黑胸大蠊',
        sciname: 'Periplaneta fuliginosa',
        logo: '/logos/m1.jpg',
        model: '/models/1.glb'
    },
    {
        id: '2',
        name: '双纹小蠊',
        sciname: 'Blattella bisignata',
        logo: '/logos/m2.jpg',
        model: '/models/2.glb'
    },
    {
        id: '3',
        name: '金边地鳖',
        sciname: 'Opisthoplatia orientalis',
        logo: '/logos/m3.jpg',
        model: '/models/3.glb'
    },
    {
        id: '4',
        name: '美洲大蠊',
        sciname: 'Periplaneta americana',
        logo: '/logos/m4.jpg',
        model: '/models/4.glb'
    },
    {
        id: '5',
        name: '日本大蠊',
        sciname: 'Periplaneta japonica',
        logo: '/logos/m5.jpg',
        model: '/models/5.glb'
    },
    {
        id: '6',
        name: '疑防硕蠊',
        sciname: 'Blaptica dubia',
        logo: '/logos/m6.jpg',
        model: '/models/6.glb'
    },
    {
        id: '7',
        name: '酱亚麻蝇',
        sciname: 'Sarcophaga dux',
        logo: '/logos/m7.jpg',
        model: '/models/7.glb'
    },
    {
        id: '8',
        name: '白纹伊蚊',
        sciname: 'Aedes albopictus',
        logo: '/logos/m8.jpg',
        model: '/models/8.glb'
    },
    {
        id: '9',
        name: '大头金蝇',
        sciname: 'Chrysomya megacephala',
        logo: '/logos/m9.jpg',
        model: '/models/9.glb'
    },
    {
        id: '10',
        name: '绯颜裸金蝇',
        sciname: 'Chrysomya rufifacies',
        logo: '/logos/m10.jpg',
        model: '/models/10.glb'
    },
    {
        id: '11',
        name: '裸芒棕蝇',
        sciname: 'Synthesiomyia nudiseta',
        logo: '/logos/m11.jpg',
        model: '/models/11.glb'
    },
    {
        id: '12',
        name: '瘦叶带绿蝇',
        sciname: 'Hemipyrellia ligurriens',
        logo: '/logos/m12.jpg',
        model: '/models/12.glb'
    },
];